@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css");
$theme-colors: (
  "primary": #49bb80,
  "color1": #1c2021,
  "secondary": #555b58,
  "danger": #ff5e5e,
);
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/scss/bootstrap";
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
@import "./color-palette";
@import "./assets/fonts/neue-helvetica/style.css";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$default-primary: mat-palette($pallet);
$default-accent: mat-palette($pallet-accent, A200, A100, A400);
// The warn palette is optional (defaults to red).
$default-warn: mat-palette($mat-red);
// Create the theme object (a Sass map containing all of the palettes).
$default-theme: mat-light-theme($default-primary,
    $default-accent,
    $default-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($default-theme);

/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
  outline: none !important;
}

body {
  font-family: "Neue Helvetica Light";
  line-height: normal;
  position: relative;
  overflow-x: hidden;
  background-color: #e8ffed ;
}

a {
  transition: ease 0.3s;
  text-decoration: none !important;
}

.minh-auto {
  min-height: auto !important;
}

button {
  transition: ease 0.3s;
}

.ofc {
  object-fit: cover !important;
}

.cursor-pointer {
  cursor: pointer;
}

.opacity-0 {
  opacity: 0;
}

// SWEET ALERT
// @import '~@sweetalert2/theme-dark/dark.scss';
app-loader {
  display: block;
}

.mat-icon {
  vertical-align: middle;
}

// DYNAMIC WIDTH
@mixin DYNAMIC_WIDTH_PERCENTAGE {
  @for $i from 1 through 100 {
    .width-#{$i} {
      width: #{$i + "%"};
    }
  }
}

@include DYNAMIC_WIDTH_PERCENTAGE;

@mixin DYNAMIC_WIDTH_PEXELS {
  @for $i from 1 through 2000 {
    .pxwidth-#{$i} {
      width: #{$i + "px"};
    }
  }
}

@include DYNAMIC_WIDTH_PEXELS;

.inputWrap {
  position: relative;

  .prefixInput {
    position: absolute;
    top: 12px;
    left: 15px;
  }

  input {
    display: flex;
    width: 100%;
    background-color: transparent;
    border: 0;
    padding: 1px 0 1px 35px;
    outline: none;
    // height: 43px;
    height: 40px;
    // box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
    // border-radius: 5px 0 0 5px;
    font-size: 12px;
    line-height: 14px;
    position: relative;

    @media (max-width: 767px) {
      font-size: 10px;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #1c2021;
      opacity: 1;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #1c2021;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #1c2021;
      opacity: 1;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: #1c2021;
      opacity: 1;
    }

    &::placeholder {
      color: #838181;
    }
  }
}

.btn {
  font-family: "Neue Helvetica Medium";
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 1rem;
}

.container {
  max-width: 1300px;
}

@media (max-width: 767px) {

  .w-75,
  .width-82 {
    width: 55% !important;
  }
}

.section-head {
  text-align: center;
  font-family: "Neue Helvetica Bold";
  font-size: 24px;
  line-height: 29px;
  color: #1c2021;
  letter-spacing: 0.065rem;
  margin-bottom: 0;
  text-transform: capitalize;
}

.section-sub-head {
  text-align: center;
  font-family: "Neue Helvetica Medium";
  font-size: 1rem;
  line-height: 20px;
  color: #26816d;
  margin-bottom: 30px;
}

.section-desc {
  font-family: "Neue Helvetica Medium";
  font-size: 14px;
  line-height: 17px;
  color: #1c2021;
}

.section-desc-light {
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #6b6b6b;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  // bottom: 10px;
}

.slick-dots li button:before {
  height: 14px;
  width: 14px;
  font-size: 10px;
}

.slick-dots li {
  margin: 0;
}

.slick-dots li.slick-active button:before {
  font-size: 14px;
  color: #49bb80;
}

.ps__thumb-y {
  width: 6px !important;
  z-index: 9;
  position: relative;
  left: -6px;
  background: #49bb80 !important;
  box-sizing: border-box;
  border-radius: 5px !important;
}

perfect-scrollbar>.ps.ps--active-y>.ps__rail-y {
  border-left: 6px solid #e7e6e6 !important;
  width: 12px !important;
  border-radius: 2px !important;
  overflow: visible;
  background: #e7e6e600 !important;
}

.mat-accordion .mat-expansion-panel {
  border-radius: 0 !important;
}

header {
  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &:last-child {
      border: 0;
    }
  }

  .mat-expansion-panel-header-title {
    font-family: "Neue Helvetica Medium";
    font-size: 15px;
    line-height: 20px;
    color: #1c2021;
  }

  .mat-expansion-panel-header {
    padding-left: 0 !important;
  }
}

.ardWrap {
  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none;

    &:last-child {
      border: 0;
    }
  }

  .mat-expansion-panel-header-title {
    font-family: "Neue Helvetica Medium";
    font-size: 15px;
    line-height: 20px;
    color: #1c2021;
  }
}

.accordionWrap {
  .mat-accordion>.mat-expansion-panel-spacing {
    border: 1px solid #c4c4c4;
  }

  .mat-expansion-panel {
    margin-bottom: 30px;
  }

  .mat-expansion-panel-body {
    padding: 24px;
    font-size: 13px;
    line-height: 19px;
    color: #6b6b6b;
  }

  .mat-expansion-panel-header {
    background: #000000 !important;

    .mat-expansion-panel-header-title {
      font-family: "Neue Helvetica Bold";
      font-size: 1rem;
      line-height: 21px;
      color: #49bb80;
    }

    .mat-expansion-indicator::after {
      color: #49bb80;
    }
  }

  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none;
  }

  .mat-expansion-panel-header.mat-expanded {
    background: #49bb80 !important;
    height: 48px;

    .mat-expansion-panel-header-title,
    .mat-expansion-indicator::after {
      color: #ffffff;
    }
  }
}

.browse {
  width: 100%;
  border-bottom: 1px solid #ebebeb;

  .browcarousel {
    .slide {
      // background: #ffffff;
      border-radius: 50% !important;
      box-sizing: border-box;
      border-radius: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 10px auto;

      img {
        display: block;
        margin: 0 auto !important;
        width: 115px;
        height: 115px;
        object-fit: fill;

        @media (max-width: 768px) {
          width: 60px;
          height: 70px;
        }
      }
    }

    p {
      text-align: center;
      font-family: "Neue Helvetica Medium";
      font-size: 1rem;
      line-height: 20px;
      color: #000000;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
}

.rating {
  padding: 0 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;

  p {
    margin-bottom: 0;
    font-size: 11px;
    line-height: 13px;
    color: #000000;
    padding-left: 5px;
  }
}

.col-cs-5 {
  margin: 15px;
  flex: 0 0 17%;
  max-width: 17%;

  @media (max-width: 992px) {
    flex: 0 0 93%;
    max-width: 93%;
  }
}

.prodType {
  width: 100%;
  border-bottom: 1px solid #ebebeb;

  .prodItem {
    font-family: "Neue Helvetica Bold";
    font-size: 14px;
    line-height: 17px;
    color: #1c2021;
    text-align: center;
    padding: 20px 0;
    cursor: pointer;

    &.active {
      border-bottom: 2px solid #006838;
    }
  }
}

.dispensary {
  width: 100%;

  // border-bottom: 1px solid #EBEBEB;
  .slide {
    width: 100%;
    position: relative;
    padding: 0;
    padding-top: 90%;
    // height: 180px;
    background: #ffffff;
    // border: 1px solid #E2E2E2;
    border: 0;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px;
    position: relative;

    // @media (max-width: 768px) {
    //   height: auto !important;
    // }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      // object-position: top;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;



      // border-radius: 10px;
      // @media (max-width: 768px) {
      //   height: 60px;
      // }
    }

    .material-icons {
      position: absolute;
      top: 15px;
      right: 15px;
      text-align: right;
      color: #c0baba;
      z-index: 2;

      &:hover {
        color: var(--primary);
      }

      &.active {
        color: #ff5e5e;
      }
    }
  }
}

.desc {
  padding: 0 10px;

  p {
    font-size: 15px;
    line-height: 22px;
    color: #6b6b6b;
    // font-weight: 500;
    margin-bottom: 3px;
    font-family: "Neue Helvetica Roman";

    &:first-child {
      font-size: 0.875rem;
    }

    &:nth-child(2) {
      font-weight: 700;
      letter-spacing: 0.00625rem;
      line-height: 1.25rem;
      font-family: "Neue Helvetica Medium";
      font-size: 17px;
      line-height: 22px;
      color: #000000;

      @media (max-width: 992px) {
        min-height: auto;
      }
    }

    &:last-child {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.prod-details {
  mat-select {
    width: 203px !important;
    height: 45px;
    background: #ffffff;
    border: 1px solid #ededed;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 0 11px;
  }

  .mat-select-value {
    padding: 11px;
    border-right: 1px solid #ededed;
  }
}

.deliveries {
  width: 230px;
  height: 43px;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 11px;
  color: #454444;

  mat-select {
    width: 100px !important;
    height: auto;
    background: #ffffff00;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0;
  }

  .mat-select-value {
    padding: 11px;
    color: #26826d;
  }
}

.map {
  width: 100%;
  height: 780px;
  object-fit: cover;
}

.filter {
  width: 119px;
  height: 33px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 17px;
  color: #1c2021;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px 15px 15px;
  font-family: "Neue Helvetica Medium";
  cursor: pointer;
}

.ngx-slider .ngx-slider-pointer {
  width: 20px !important;
  height: 20px !important;
  top: -9px !important;
  background-color: #fff !important;
}

.ngx-slider .ngx-slider-selection {
  background-color: var(--primary) !important;
}

.ngx-slider .ngx-slider-pointer:after {
  width: 14px !important;
  height: 14px !important;
  top: 3px !important;
  left: 3px !important;
  border-radius: 50% !important;
  background: #49bb80 !important;
}

.ngx-slider .ngx-slider-bubble {
  font-family: "Neue Helvetica Medium" !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #5b6661 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary);
}

.ltabs {
  ul {
    li {
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #838a8b;

      &:hover {
        color: #1c2021;
        cursor: pointer;
      }

      &.active {
        color: #ffffff;
        background-color: var(--primary);
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
}

.featured {
  width: 100%;

  // border-bottom: 1px solid #EBEBEB;
  .slide {
    width: 100%;
    height: 180px;
    background: #ffffff;
    border: 0;
    box-sizing: border-box;
    border-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      //   border-radius: 10px;
      @media (max-width: 768px) {
        height: 70px;
      }
    }

    .material-icons {
      position: absolute;
      top: 15px;
      right: 15px;
      text-align: right;
      color: #c0baba;

      &:hover,
      &.active {
        color: #ff5e5e;
      }
    }
  }
}

.emailcheckbox {
  margin-bottom: 20px;

  .mat-checkbox-inner-container {
    margin: auto;
    margin-top: 2px;
    margin-right: 15px;
    font-family: "Neue Helvetica Medium";
  }

  .mat-checkbox-label {
    line-height: 16px;
    white-space: normal;

    small {
      font-family: "Neue Helvetica Medium";

      // word-break: break-all;
      @media (max-width: 992px) {
        word-break: break-word;
      }

      a {
        color: #26816d;
      }
    }
  }
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  border: 2px solid rgba(221, 221, 221, 0);
  display: inline-flex;
}

.number-input,
.number-input * {
  box-sizing: border-box;
}

.number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  cursor: pointer;
  margin: 0;
  position: relative;
  background: #dbdbdb;
}

.number-input button:after {
  width: 27px;
  height: 27px;
  display: inline-block;
  position: absolute;
  content: "";
  top: 0;
  left: 0;
}

.number-input button.plus:after {
  content: "";
  background: url(./assets/images/add.svg) center center no-repeat;
}

.number-input button.minus:after {
  content: "";
  background: url(./assets/images/minus.svg) center center no-repeat;
}

.number-input input[type="number"] {
  max-width: 3rem;
  padding: 0.5rem;
  height: 27px;
  border-color: transparent;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000000;
}

.track {
  position: absolute;
  right: 15px;
  bottom: -15px;
  object-fit: contain !important;
  width: 36px !important;
  height: 46.79px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffffff;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--primary);
}

.mat-slide-toggle-bar {
  width: 40px !important;
  height: 22px !important;
  border-radius: 13px !important;
}

.mat-slide-toggle-thumb-container {
  top: 1px !important;
  left: 2px !important;
}

.mat-dialog-container {
  box-shadow: none !important;
  background: transparent !important;
}

.modalwrap {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  position: relative;
  max-width: 805px;
  height: auto;
  padding: 30px 100px;

  @media (max-width: 992px) {
    padding: 30px 20px;
  }

  .modalClose {
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -10px;
    background: var(--primary);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .material-icons {
      color: #ffffff;
    }
  }

  h1 {
    font-family: "Neue Helvetica Bold";
    font-size: 2.14rem;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
  }

  p {
    font-size: 15px;
    line-height: 24px;
    text-align: center;
  }

  mat-select {
    width: 100%;
    height: 45px;
    background: #ffffff;
    border: 1px solid #ededed;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 11px;
  }
}

// .mat-select-arrow {
// width: 10px!important;
// height: 10px!important;
// border-left: 2px solid rgba(0,0,0,.54)!important;
// border-right: 0 solid rgba(0,0,0,.54)!important;
// border-top: 2px solid!important;
// margin: 0 0 9px 10px!important;
// transform: rotate(-134deg);
// }
.account-modal {
  .modalwrap {
    width: 510px;
    max-width: 100%;
    padding: 0;

    h4 {
      background: black;
      width: 100%;
      font-size: 1rem;
      color: white;
      padding: 15px;
    }
  }

  .profile-field {
    .mat-calendar {
      margin: 0 auto;
    }
  }
}

.ngx-pagination li {
  font-size: 12px;
}

.ngx-pagination .current {
  background: #49bb80 !important;
  border-radius: 50%;
  padding: 0 !important;
  height: 28px;
  width: 28px;
  text-align: center;
  line-height: 28px;
}

.ngx-pagination {
  padding-left: 0;
  text-align: center;
}

.modalwrap {
  .rating {
    text-align: center;

    .star {
      width: 25px !important;
    }
  }

  input {
    width: 350px;
    max-width: 100%;
    height: 40px;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    margin-bottom: 10px;
    font-size: 14px;
    padding: 0 10px;
    display: block;
    max-width: 10px auto;
  }

  textarea {
    width: 350px;
    max-width: 100%;
    height: 100px;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    margin-bottom: 10px;
    font-size: 14px;
    padding: 0 10px;
    display: block;
    max-width: 10px auto;
    resize: none;
  }
}

.logo {
  margin-left: 10px;
  width: 195px;
  padding-right: 1rem;

  @media (max-width: 1024px) {
    padding-right: 0rem;
  }
}

.card-white {
  background: #fff;
  border-radius: 0%;
  border: 1px solid #dbdbdb;
  display: block;
  margin-left: auto;
  width: auto;
  text-align: right;
  font-size: 24px;
  padding: 10px;

  * {
    font-size: 16px;
    font-weight: bold;
  }
}

.loginarea .login-right .formitem label {
  text-align: left;
}

.retailer-img-marker {
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: 2px solid black;
}

.left-panel {
  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: none !important;
  }

  .mat-expansion-panel-header {
    padding-left: 0;
    height: 32px;
    color: #575a5a;
    font-size: 13px;
    opacity: 0.8;
  }
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
  left: inherit !important;
  top: 0 !important;
  bottom: inherit !important;
  right: 10 !important;
}

.swal2-title {
  font-size: 1rem !important;
  line-height: 1.5;
  font-family: "Neue Helvetica Medium";
  text-transform: capitalize !important;
  font-weight: normal !important;
}

button.swal2-confirm.swal2-styled,
button.swal2-styled.swal2-cancel {
  font-family: "Neue Helvetica Medium";
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem 1rem;
  background: red;
  border: 1px solid red;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  color: #fff;
  min-width: 150px;

  &:hover,
  &:active,
  &:focus {
    background-color: #3ca16d;
    border-color: #399867;
  }
}

button.swal2-styled.swal2-cancel {
  background-color: red;
  border-color: red;
  color: #fff;

  &:hover,
  &:active,
  &:focus {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    border-color: #757575;
  }
}

.calender {
  &.disabled {
    margin-left: 15px !important;

    .top_most_header {
      &>div {
        &:first-child {
          pointer-events: none;
          cursor: not-allowed;
        }
      }
    }

    &+.calender_action {
      margin-bottom: 15px;
    }
  }
}

.prev.disable,
.next.disable {
  opacity: 0.5;
}

.loginarea .login-right .btn-submit {
  font-size: 13px;
}

@media (max-width: 767px) {
  .cdk-overlay-pane {
    max-width: 100% !important;
  }
}

.text-small {
  font-size: small;
}

.autocomp {
  margin-bottom: 20px;

  input {
    background-color: transparent !important;
    width: 100% !important;
    height: 54px;
    background: #1a1f26;
    border: 1px solid #353d48 !important;
    box-sizing: border-box;
    border-radius: 7px;
    padding-left: 15px !important;
    font-size: 12px;
    line-height: 14px;
    color: #758498;

    &::placeholder {
      font-family: "Roboto";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #758498;
    }
  }

  .mat-error {
    margin: 0;
  }
}

.pac-logo {
  position: relative;
}

.btn-danger {
  color: #fff !important;
  background-color: #ff5e5e !important;
  border-color: #ff5e5e !important;
}

// .customMapStyle {
//     transform: scale(2.1);
// }

.calenderBox {
  margin-bottom: 20px;
}

.searchWrap:focus,
.searchWrap:focus-within,
.searchWrap:active {
  background: #fff !important;
  border: 0.0625rem solid rgb(204, 204, 204) !important;
}

.top__spacecontrol {
  margin-top: 1rem;
  position: relative;
}

.bottom__controller {
  margin-bottom: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.gm-style .gm-style-iw-c {
  min-height: 140px;
}

.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.addphn {
  margin-bottom: 10px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      width: 100%;
      margin-bottom: 10px;
      font-family: "Neue Helvetica Medium";
      font-size: 11px;
      line-height: 14px;
      padding-left: 21px;
      position: relative;
      cursor: pointer;

      mat-icon {
        position: absolute;
        width: auto;
        height: auto;
        margin-right: 5px;
        color: #26826d !important;
        top: 0px !important;
        left: 0px !important;
        right: inherit !important;
        font-size: 17px !important;
        line-height: 1.1 !important;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.prodetailItem li {
  &:nth-child(3) {
    // flex-basis: 100%;
    // max-width: 100%;
    order: 1;
  }

  &:last-child {
    // flex-basis: 100%;
    // max-width: 100%;
    order: 2;
  }

  &:nth-child(1) {
    p {
      font-family: "Neue Helvetica Bold" !important;
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }

  &:nth-child(2) {
    p {
      font-family: "Neue Helvetica Bold" !important;
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }
}

.descriptions {
  p {
    font-weight: normal !important;
    font-family: "Neue Helvetica Light" !important;
    font-size: 15px !important;
    line-height: 23px !important;
  }
}

.logo:hover {
  cursor: pointer;
}

.back-to-result:hover {
  cursor: pointer;
}

.top-space-one {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 10px;
}

// html.cdk-global-scrollblock{
//     top:0% !important
// }
.click-disable {
  pointer-events: none;
}

.cdk-global-overlay-wrapper {
  .cdk-overlay-pane {
    .mat-dialog-container {
      overflow: inherit;
      position: relative;
      z-index: 999;
    }
  }
}

@media (max-width: 767px) {
  .mobile-margin-top-30 {
    margin-top: 30px;
  }
}

.mobile-d-none {
  @media (min-width: 768px) {
    display: flex !important;
  }

  @media (max-width: 767px) {
    display: none !important;
  }
}

.desktop-d-none {
  @media (min-width: 768px) {
    display: none !important;
  }

  @media (max-width: 767px) {
    display: block !important;
  }
}

.content_area {
  background: #e8ffed;
  padding:0 15px !important;
 

}

.cp {
  cursor: pointer;
}

// .name {
//   font-size: 23px;
//   line-height: 28px;
//   color: var(--primary);
//   font-family: "Neue Helvetica Bold";
//   margin-bottom: 1rem;
// }
.pagination {
  justify-content: center;
  margin-top: 10px;
}

.mat-option {
  .mat-option-text {
    img {
      width: 30px;
    }
  }
}

.error {
  color: red;
  font-size: 12px;
  line-height: 20px;
}

.promotion-holder {
  padding: 10px 0;

  .slide {
    .text_promoCode {
      h4 {
        margin-top: 20px;
      }
    }
  }
}


.ngx-mat-timepicker-dialog {
  background: #fff;
}




.timepicker-period__warning {
  width: 100% !important;
}

.timepicker-period {
  position: initial !important;
}
// .gm-style img{
//   max-width: 15px !important;
//   max-height: 15px;
// }

.matmenuone{

    .mat-menu-content{
    padding-top: 0px !important;
    border-radius: 4px;
  table{
    th{
      border-radius: 0px !important;
    }
  }
  }
  }
  .menu-one{
    display: block;
  }
  .close-icon-one{
    display: none !important;
  }
  .small_sidebar{
    .result-list{
      left: 0px !important;
  }
  .menu-one{
    display: none;
  }
  .close-icon-one{
    display: block !important;
  }
  }

  .purchase_btn{
  &.disabledButton{
    opacity: 0.5;
  }
}
.no_scroll{
  height: 100vh;
  overflow-y: hidden;
  position: relative;
  .wrapperOverlay{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }
}

.modal_newWrap_table{
  .mainImage{
    max-width: 250px;
    margin: 0 auto;
  }
  .allInner_infoDetails{
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .moodalBody{
    max-height: 20vh !important;
  }
}